import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from '@components/layout';
import SEO from '@components/seo';
import ContactStyles from '@styles/contactPage';

export default function MessageFailedPage({ data: { theme, contact } }) {
  return (
    <Layout page="/contact-us">
      <SEO title="Message Failed!" keywords={contact.tags} />
      <ContactStyles>
        <div className="contact-header">
          <h1>Contact Us</h1>
        </div>

        <div className="contact-container">
          <div className="thank-you-wrapper">
            <h2>Your message could not be sent. Please try again.</h2>
          </div>

          <div className="contact-information">
            <div>
              <h3 className="address-header">Contact Information</h3>
              <span>Dutch Rentalz</span>
              <span>{theme.data.street_address.text}</span>
              <span>
                {theme.data.city.text}, {theme.data.state.text}
                {` `}
                {theme.data.zip_code.text}
              </span>
              <a
                href={`tel:${theme.data.phone_number.text.replace(
                  /[- )(]/g,
                  ``
                )}`}
              >
                {theme.data.phone_number.text}
              </a>
            </div>
            <div>
              <h3>Hours</h3>
              {theme.data.hours.map(day => (
                <p key={day.days.text}>
                  {day.days.text}: &nbsp;
                  {day.closed.toLowerCase() === `true`
                    ? `Closed`
                    : `${day.open.text} - ${day.close.text}`}
                </p>
              ))}
            </div>
          </div>
        </div>
      </ContactStyles>
    </Layout>
  );
}

MessageFailedPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export const query = graphql`
  query messageFailedPage {
    theme: prismicThemeSettings {
      id
      data {
        street_address {
          text
        }
        city {
          text
        }
        state {
          text
        }
        zip_code {
          text
        }
        phone_number {
          text
        }
        hours {
          closed
          days {
            text
          }
          open {
            text
          }
          close {
            text
          }
        }
      }
    }
    contact: prismicContactPage {
      tags
      data {
        thank_you_message {
          html
        }
      }
    }
  }
`;
